<template>
  <div id="app">
    <div
      class="p-2 position-fixed w-100 top-0 bg-success"
      style="z-index: 999"
      v-if="updateExists"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col my-auto">
            <p class="text-white mb-0">
              There is an update available for the Amchara website! To receive
              the latest updates please reload the page or click the button
            </p>
          </div>
          <div class="col-auto ms-auto my-auto">
            <button
              @click="refreshApp"
              class="btn btn-sm btn-outline-light fw-bold"
            >
              <i class="far fa-redo-alt me-2"></i>Update
            </button>
          </div>
        </div>
      </div>
    </div>
    <div id="nav">
      <blog-nav
        v-if="$route.path.includes('blog') || $store.isBlogPost"
      ></blog-nav>
      <main-nav v-else></main-nav>
    </div>
    <router-view />
    <div id="footer">
      <main-footer></main-footer>
    </div>
  </div>
</template>
<script>
import MainNav from "./views/layouts/MainNav";
import BlogNav from "./views/layouts/BlogNav";
import MainFooter from "./views/layouts/MainFooter";

export default {
  metaInfo: {
    title: "Amchara Limited",
    titleTemplate:
      "%s | Amchara Health Retreats are for anyone who wishes to change their physical health, emotional well-being and lifestyle that drives these in a positive, sustainable way. Amchara&#039;s motto is Change for Good",
  },
  data() {
    return {
      refreshing: false,
      registration: null,
      updateExists: false,
    };
  },
  methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.updateExists = true;
      this.refreshApp();
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage("skipWaiting");
    },
    fetchSeo() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/website/seo-metas")
        .then(({ data }) => {
          this.$store.seo_metas = data.seo_metas;

          // const seoMetasLocalStorage = localStorage.getItem('seo_metas');
        });
    },
  },
  created() {
    this.fetchSeo();
    // Checking for service worker updates
    document.addEventListener("applicationUpdated", this.showRefreshUI, {
      once: true,
    });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener("controllerchange", () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  components: {
    MainNav,
    BlogNav,
    MainFooter,
  },
};
</script>

<style lang="scss">
</style>
