import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'


// Styling
// import 'bootstrap';

import * as bootstrap from "bootstrap/dist/js/bootstrap.esm";
Vue.prototype.$bootstrap = bootstrap

import "@/assets/styles/app.scss";


import axios from "axios"
Vue.prototype.$axios = axios

Vue.prototype.$axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.prototype.$axios.defaults.headers.common['Accept'] = 'application/json'

import VueMeta from 'vue-meta'

Vue.use(VueMeta, {
  // optional pluginOptions
  // refreshOnceOnNavigation: true
})

import VueStash from 'vue-stash';
import store from './store/store'

Vue.use(VueStash)

Vue.config.productionTip = false


// Filters
Vue.filter('formatDate', (date) => {
  return moment(date).format('LL');
})

Vue.filter('formatDateTime', (date) => {
  return moment(date).format('LLL');
})
// End filters


new Vue({
  data: {
    store
  },
  router,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')