<template>
  <div>
    <footer class="footer" style="background-color: #ddd">
      <div class="container main-footer">
        <div class="row py-4 text-center">
          <div class="col-md-4">
            <h3>Amchara Limited</h3>
            <div class="row">
              <div class="col-md-6">
                <b>Somerset, UK</b>
                <ul class="list-unstyled">
                  <li>
                    <router-link to="/uk-retreat">UK Retreat</router-link>
                  </li>
                  <li>
                    <router-link to="/treatments">UK Treatments</router-link>
                  </li>
                  <li>
                    <router-link to="/uk-gallery">UK Gallery</router-link>
                  </li>
                  <!--<li>
                    <router-link to="/team">UK Team</router-link>
                  </li>-->
                </ul>
              </div>
              <div class="col-md-6">
                <b>Gozo, Malta</b>
                <ul class="list-unstyled">
                  <li>
                    <router-link to="/malta-retreat">Malta Retreat</router-link>
                  </li>
                  <li>
                    <router-link to="/malta-treatments"
                      >Malta Treatments</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/malta-gallery">Malta Gallery</router-link>
                  </li>
                  <!--<li>
                    <router-link to="/team">Malta Team</router-link>
                  </li>-->
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h3>About Amchara</h3>
            <div class="row">
              <div class="col-md-12">
                <ul class="list-unstyled">
                  <!--<li>
                    <router-link to="/user-area">User Area</router-link>
                  </li>-->
                  <li>
                    <router-link to="/blog">Amchara Blog</router-link>
                  </li>
                  <!--<li>
                    <router-link to="/login">Staff Login</router-link>
                  </li>-->
                  <li>
                    <router-link to="/terms-and-conditions"
                      >Terms & Conditions</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/privacy">Privacy</router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <h3>Contact Us</h3>
            <ul class="list-unstyled">
              <li>
                <a href="tel:+441823213111">T: 01823 213 111</a>
              </li>
              <li>
                <a href="mailto:change@amchara.com">E: change@amchara.com</a>
              </li>
            </ul>

            <a
              href="https://facebook.com/amcharahealth"
              target="_blank"
              style="color: #575756"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-facebook fa-stack-1x fa-inverse"></i>
              </span>
            </a>

            <a
              href="https://twitter.com/amcharahealth"
              target="_blank"
              style="color: #575756"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-twitter fa-stack-1x fa-inverse"></i>
              </span>
            </a>

            <a
              href="https://instagram.com/amcharahealth"
              target="_blank"
              style="color: #575756"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-instagram fa-stack-1x fa-inverse"></i>
              </span>
            </a>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <div class="row p-2 footer-copyright text-center">
          <div class="col-md-12">
            <span>
              &copy; {{ new Date().getFullYear() }}
              <a
                href="https://www.google.co.uk/maps/place/Amchara+Health+Retreats/@51.0092881,-3.2810421,17z/data=!4m8!1m2!2m1!1sBindon+Country+House,+Langford+Budville,+Somerset,+TA21+0RU!3m4!1s0x486deda8343136fd:0x7cf19b4286a3bfec!8m2!3d51.0092845!4d-3.2788481"
              >
                Amchara Detox Retreats, Bindon Country House, Langford Budville,
                Somerset, TA21 0RU, UK
              </a>
            </span>
          </div>
        </div>
      </div>
    </footer>

    <!-- Modal
<div class="modal fade" id="popupMessage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="border-bottom:none;padding-bottom:0;">
        <button type="button" class="close popup-close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5 order-md-2 text-center">
        <img width="290" src="@/assets/images/popups/reopening-graphic_photo.jpg" alt="Amchara Health Resorts UK" class="img-fluid">
        </div>
          <div class="col-md-7 order-md-1">
        <p class="text-center"><img width="290" src="@/assets/images/popups/reopening-graphic_title.jpg" alt="Amchara Health Resorts Re-opening May" class="img-fluid"></p>
        <p>Amchara Somerset is reopening on the <strong>17th May</strong>. Book before 12th March and pay 2020 prices!</p>
        </div>
        </div>
      </div>
      <div class="modal-footer" style="border-top:none;justify-content:center;padding-top:0;">
        <a href="/enquiries?ref=reopening" type="button" class="btn btn-orange-gradient enquire-now-button popup-close">ENQUIRE NOW</a>
      </div>
    </div>
  </div>
</div>-->
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>