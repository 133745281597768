import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //
  {
    path: '/',
    name: 'Home',
    component: function () {
      return import( /* webpackChunkName: "pages-home" */ '../views/pages/Home.vue')
    }
  },


  {
    path: '/blog',
    name: 'BlogIndex',
    component: function () {
      return import( /* webpackChunkName: "pages-blog" */ '../views/blog-posts/Index.vue')
    }
  },






  // Pages
  {
    path: '/enquiries',
    name: 'Enquiries',
    component: function () {
      return import( /* webpackChunkName: "pages-enquiries" */ '../views/pages/Enquiries.vue')
    }
  },
  {
    path: '/hormone-health',
    name: 'HormoneHealth',
    component: function () {
      return import( /* webpackChunkName: "pages-hormone-health" */ '../views/pages/HormoneHealth.vue')
    }
  }

  ,
  {
    path: '/functional-health-in-action',
    name: 'FunctionalHealthInAction',
    component: function () {
      return import( /* webpackChunkName: "pages-functional-health-in-action" */ '../views/pages/FunctionalHealthInAction.vue')
    }
  }

  ,
  {
    path: '/juice-fasting',
    name: 'JuiceFasting',
    component: function () {
      return import( /* webpackChunkName: "pages-juice-fasting" */ '../views/pages/JuiceFasting.vue')
    }
  }

  ,
  {
    path: '/tailored-nutrition',
    name: 'TailoredNutrition',
    component: function () {
      return import( /* webpackChunkName: "pages-tailored-nutrition" */ '../views/pages/TailoredNutrition.vue')
    }
  }

  ,
  {
    path: '/why-come-to-amchara',
    name: 'WhyComeToAmchara',
    component: function () {
      return import( /* webpackChunkName: "pages-why-come-to-amchara" */ '../views/pages/WhyComeToAmchara.vue')
    }
  }


  ,
  {
    path: '/whats-included',
    name: 'WhatsIncluded',
    component: function () {
      return import( /* webpackChunkName: "pages-whats-included" */ '../views/pages/WhatsIncluded.vue')
    }
  },
  {
    path: '/360-health',
    name: 'ThreeSixtyHealth',
    component: function () {
      return import( /* webpackChunkName: "pages-360-health" */ '../views/pages/ThreeSixtyHealth.vue')
    }
  },
  {
    path: '/uk-retreat',
    name: 'UkRetreat',
    component: function () {
      return import( /* webpackChunkName: "pages-uk-retreat" */ '../views/pages/UkRetreat.vue')
    }
  },
  {
    path: '/malta-retreat',
    name: 'MaltaRetreat',
    component: function () {
      return import( /* webpackChunkName: "pages-malta-retreat" */ '../views/pages/MaltaRetreat.vue')
    }
  },
  {
    path: '/uk-pricing',
    name: 'UkPricing',
    component: function () {
      return import( /* webpackChunkName: "pages-uk-pricing" */ '../views/pages/UkPricing.vue')
    }
  },
  {
    path: '/uk-gallery',
    name: 'UkGallery',
    component: function () {
      return import( /* webpackChunkName: "pages-uk-gallery" */ '../views/pages/UkGallery.vue')
    }
  }

  ,
  {
    path: '/uk-rooms',
    name: 'UkRooms',
    component: function () {
      return import( /* webpackChunkName: "pages-uk-rooms" */ '../views/pages/UkRooms.vue')
    }
  }

  ,
  {
    path: '/retreat-information',
    name: 'RetreatInformation',
    component: function () {
      return import( /* webpackChunkName: "pages-retreat-information" */ '../views/pages/RetreatInformation.vue')
    }
  }

  ,
  {
    path: '/retreat-information-malta',
    name: 'RetreatInformationMalta',
    component: function () {
      return import( /* webpackChunkName: "pages-retreat-information-malta" */ '../views/pages/RetreatInformationMalta.vue')
    }
  }

  ,
  {
    path: '/malta-gallery',
    name: 'MaltaGallery',
    component: function () {
      return import( /* webpackChunkName: "pages-malta-gallery" */ '../views/pages/MaltaGallery.vue')
    }
  }

  ,
  {
    path: '/malta-pricing',
    name: 'MaltaPricing',
    component: function () {
      return import( /* webpackChunkName: "pages-malta-pricing" */ '../views/pages/MaltaPricing.vue')
    }
  },
  {
    path: '/malta-rooms',
    name: 'MaltaRooms',
    component: function () {
      return import( /* webpackChunkName: "pages-malta-rooms" */ '../views/pages/MaltaRooms.vue')
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: function () {
      return import( /* webpackChunkName: "pages-team" */ '../views/pages/Team.vue')
    }
  },
  {
    path: '/bookings',
    name: 'Bookings',
    component: function () {
      return import( /* webpackChunkName: "pages-bookings" */ '../views/pages/Bookings.vue')
    }
  },
  {
    path: '/treatments',
    name: 'TreatmentsUk',
    component: function () {
      return import( /* webpackChunkName: "pages-treatments" */ '../views/pages/TreatmentsUk.vue')
    }
  },
  {
    path: '/malta-treatments',
    name: 'TreatmentsMalta',
    component: function () {
      return import( /* webpackChunkName: "pages-malta-treatments" */ '../views/pages/TreatmentsMalta.vue')
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: function () {
      return import( /* webpackChunkName: "pages-privacy" */ '../views/pages/Privacy.vue')
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: function () {
      return import( /* webpackChunkName: "pages-terms-and-conditions" */ '../views/pages/TermsAndConditions.vue')
    }
  },
  {
    path: '/thanks-for-your-enquiry',
    name: 'ThanksForYourEnquiry',
    component: function () {
      return import( /* webpackChunkName: "pages-thanks-for-your-enquiry" */ '../views/pages/ThanksForYourEnquiry.vue')
    }
  }

  ,
  {
    path: '/newsletter-confirmation',
    name: 'NewsletterConfirmation',
    component: function () {
      return import( /* webpackChunkName: "pages-newsletter-confirmation" */ '../views/pages/NewsletterConfirmation.vue')
    }
  }

  ,
  {
    path: '/newsletter-confirmation-thanks',
    name: 'NewsletterConfirmationThanks',
    component: function () {
      return import( /* webpackChunkName: "pages-newsletter-confirmation-thanks" */ '../views/pages/NewsletterConfirmationThanks.vue')
    }
  }

  ,
  {
    path: '/book-now-for-christmas-and-new-year',
    name: 'BookNowForChristmasAndNewYear',
    component: function () {
      return import( /* webpackChunkName: "pages-book-now-for-christmas-and-new-year" */ '../views/pages/BookNowForChristmasAndNewYear.vue')
    }
  },
  // {
  //   path: '/loyalty-scheme',
  //   name: 'LoyaltyScheme',
  //   component: function () {
  //     return import( /* webpackChunkName: "pages-loyalty-scheme" */ '../views/pages/LoyaltyScheme.vue')
  //   }
  // },
  {
    path: '/dr-gayetri',
    name: 'DrGayetri',
    component: function () {
      return import( /* webpackChunkName: "pages-dr-gayetri" */ '../views/pages/DrGayetri.vue')
    }
  },
  {
    path: '/retreat-opening',
    name: 'RetreatOpening',
    component: function () {
      return import( /* webpackChunkName: "pages-retreat-opening" */ '../views/pages/landing/RetreatOpening.vue')
    }
  },
  {
    path: '/improve-your-wellbeing',
    name: 'ImproveYourWellbeing',
    component: function () {
      return import( /* webpackChunkName: "pages-improve-your-wellbeing" */ '../views/pages/ImproveYourWellbeing.vue')
    }
  },

  {
    path: '/investors',
    name: 'investors',
    component: function () {
      return import( /* webpackChunkName: "pages-investors" */ '../views/pages/Investors.vue')
    }
  },

  // {
  //   path: '/free-flight-offer',
  //   name: 'FreeFlightOffer',
  //   component: function () {
  //     return import( /* webpackChunkName: "pages-free-flight-offer" */ '../views/pages/FreeFlightOffer.vue')
  //   }
  // },

   //{
     //path: '/special-offers',
     //name: 'SpecialOffers',
     //component: function () {
       //return import(/* webpackChunkName: "pages-special-offers" */ '../views/pages/SpecialOffers.vue')
       //return import(/* webpackChunkName: "pages-special-offers" */ '../views/pages/SpecialOffersNewYearOffer.vue')
     //}
   //},

  {
    path: '/:cat',
    name: 'BlogCategoryView',
    component: function () {
      return import( /* webpackChunkName: "pages-blog-category-view" */ '../views/blog-posts/CategoryView.vue')
    }
  },

  {
    path: '/:cat/:id',
    name: 'BlogView',
    component: function () {
      return import( /* webpackChunkName: "pages-blog-view" */ '../views/blog-posts/View.vue')
    }
  },


  {
    // will match everything
    path: '*',
    name: 'NotFound',
    component: function () {
      return import( /* webpackChunkName: "pages-not-found" */ '../views/pages/NotFound.vue')
    }
  }
  //

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  // At the initial entry point..
  if (!from.query.test && to.query.ads_source && to.query.ads_campaign) {
    // .. save these params (sorry, getting $store here sucks).
    sessionStorage.setItem('traffic_source', to.query.ads_source);
    sessionStorage.setItem('traffic_campaign', to.query.ads_campaign);
  }

  next();
});

export default router