<template>
  <div>
    <!-- Header 1 -->
    <nav class="amchara-header">
      <div class="container my-2 my-md-0">
        <div class="row text-center py-md-1">
          <div class="col mx-auto my-auto d-none d-md-block">
            <a
              href="https://facebook.com/amcharahealth"
              target="_blank"
              style="color: #ccc"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-facebook-f fa-stack-1x fa-inverse"></i>
              </span>
            </a>

            <a
              href="https://twitter.com/amcharahealth"
              target="_blank"
              style="color: #ccc"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-twitter fa-stack-1x fa-inverse"></i>
              </span>
            </a>

            <a
              href="https://instagram.com/amcharahealth"
              target="_blank"
              style="color: #ccc"
            >
              <span class="fa-stack">
                <i class="fas fa-circle" style="font-size: 2rem"></i>
                <i class="fab fa-instagram fa-stack-1x fa-inverse"></i>
              </span>
            </a>
          </div>
          <div class="col mx-auto pt-sm-2 pb-sm-2 my-auto d-none d-md-block">
            <a href="tel:+441823213111" style="color: #333">
              +44 (0) 1823 213 111
            </a>
          </div>
          <div class="col-8 col-md-3 my-auto mx-auto">
            <router-link to="/">
              <img
                src="@/assets/images/brand/logo.png"
                style="width: 35px"
                alt="Amchara Healthcare Logo"
                class="d-none d-xs-none d-sm-none d-md-inline"
              />
              <img
                src="@/assets/images/brand/logo.svg"
                alt="Amchara Healthcare Logo"
                class="d-md-none img-fluid"
                width="407"
              />
            </router-link>
          </div>
          <div class="col mx-auto my-auto d-none d-md-block">
            <router-link to="/blog" class="btn btn-sm btn-green-gradient">
              <span class="d-none d-md-inline">HEALTH HUB</span>
            </router-link>
          </div>
          <div class="col-2 mx-auto my-auto px-2">
            <router-link
              to="/enquiries"
              class="btn btn-sm btn-orange-gradient d-none d-xs-none d-sm-none d-md-block"
            >
              CONTACT US
              <span class="d-md-inline">TODAY</span>
            </router-link>
            <a href="tel:+441823213111" class="d-md-none">
              <img
                src="@/assets/images/icons/icon-phone.svg"
                width="77"
                height="77"
                alt="Telephone"
                class="img-fluid"
              />
            </a>
          </div>
          <div class="col-2 mx-auto my-auto d-md-none">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#mainNavbarCollapse"
              aria-controls="mainNavbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              style="border: none; padding: 0; background: transparent"
            >
              <img
                src="@/assets/images/icons/icon-mobile-menu.svg"
                width="70"
                height="57"
                alt="Telephone"
                class="img-fluid"
              />
            </button>
          </div>
        </div>
      </div>
    </nav>

    <!-- Header 2 -->
    <nav
      class="navbar navbar-light bg-light navbar-static-top navbar-expand-md pb-1 pb-md-0 am-nav"
    >
      <div class="container">
        <!-- Collapsed Hamburger -->

        <div class="collapse navbar-collapse" id="mainNavbarCollapse">
          <!-- Left Side Of Navbar -->
          <!-- <ul class="nav navbar-nav">&#xA0;</ul> -->
          <!-- Right Side Of Navbar -->
          <ul class="navbar-nav ms-auto me-auto">
            <!-- Nav Links -->
            <!-- <li class="nav-item px-2">
              <a
                href="/free-flight-offer"
                class="nav-link font-weight-bold"
                style="color: #f78a21!important"
              >Special Offers</a>
            </li> -->

            <!-- Amchara Experience DropDown -->
            <li class="dropdown nav-item px-2">
              <div
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                Amchara Experience
              </div>
              <ul class="dropdown-menu" role="menu">
                <li
                  class="dropdown-item"
                  :class="path == 'functional-health-in-action' ? 'active' : ''"
                >
                  <router-link to="/functional-health-in-action"
                    >Personalised Health Detox</router-link
                  >
                </li>
                <li
                  class="dropdown-item"
                  :class="path == 'juice-fasting' ? 'active' : ''"
                >
                  <router-link to="/juice-fasting">Juice Fasting</router-link>
                </li>
                <li
                  class="dropdown-item"
                  :class="path == 'tailored-nutrition' ? 'active' : ''"
                >
                  <router-link to="/tailored-nutrition"
                    >Tailored Nutrition</router-link
                  >
                </li>
                <li
                  class="dropdown-item"
                  :class="path == 'hormone-health' ? 'active' : ''"
                >
                  <router-link to="/hormone-health">Hormone Health</router-link>
                </li>
                <!--<li
                  class="dropdown-item"
                  :class="path == 'why-come-to-amchara' ? 'active' : ''"
                >
                  <a href="/why-come-to-amchara">Why Come to Amchara</a>
                </li>-->
                <li
                  class="dropdown-item"
                  :class="path == 'whats-included' ? 'active' : ''"
                >
                  <router-link to="/whats-included"
                    >What's Included</router-link
                  >
                </li>
                <li
                  class="dropdown-item"
                  :class="path == '360-health' ? 'active' : ''"
                >
                  <router-link to="/360-health">360˚ Health</router-link>
                </li>
              </ul>
            </li>

            <!-- Retreat Locations DropDown -->
            <li class="dropdown nav-item px-2">
              <div
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                Retreat Locations
              </div>
              <ul class="dropdown-menu" role="menu">
                <li
                  class="dropdown-item"
                  :class="path == 'uk-retreat' ? 'active' : ''"
                >
                  <router-link to="/uk-retreat">
                    Amchara UK
                  </router-link>
                </li>
                <li
                  class="dropdown-item"
                  :class="path == 'malta-retreat' ? 'active' : ''"
                >
                  <router-link to="/malta-retreat">
                    Amchara Malta
                  </router-link>
                </li>
              </ul>
            </li>

            <!-- Pricing DropDown -->
            <li class="dropdown nav-item px-2">
              <div
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                Pricing
              </div>
              <ul class="dropdown-menu" role="menu">
                <li class="dropdown-item">
                  <router-link to="/uk-pricing">Somerset Retreat</router-link>
                </li>
                <li class="dropdown-item">
                  <router-link to="/malta-pricing">Gozo Retreat</router-link>
                </li>

                <li class="dropdown-item">
                  <router-link to="/bookings">Book Now</router-link>
                </li>

                <!--<li class="dropdown-item xmas-item">
                  <a href="/book-now-for-christmas-and-new-year">
                    Christmas &
                    New Year
                  </a>
                </li>-->
                <!--                         <li class="dropdown-item">
    <a href="/free-flight-offer" style="font-weight: bold">Free Flight Offer</a>
                </li>-->
              </ul>
            </li>

            <!-- Retreat Locations DropDown -->
            <li class="dropdown nav-item px-2">
              <div
                class="dropdown-toggle nav-link"
                data-toggle="dropdown"
                role="button"
                aria-expanded="false"
              >
                Contact Us
              </div>
              <ul class="dropdown-menu" role="menu">
                <li
                  class="dropdown-item"
                  :class="path == 'enquiries' ? 'active' : ''"
                >
                  <router-link to="/enquiries">Enquire Now</router-link>
                </li>
                <!--<li
                  class="dropdown-item"
                  :class="path == 'book-now-for-christmas-and-new-year' ? 'active' : ''"
                >
                  <a href="/book-now-for-christmas-and-new-year">Book for Xmas</a>
                </li>-->
                <li
                  class="dropdown-item"
                  :class="path == 'booking' ? 'active' : ''"
                >
                  <router-link to="/bookings">Book Now</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  computed: {
    path() {
      return this.$route.currentPath;
    },
  },
  watch: {
    $route(to, from) {
      if (to.path != "/") {
        var w = window.innerWidth;
        if (w <= 768) {
          const menuToggle = document.getElementById("mainNavbarCollapse");
          const bsCollapse = new this.$bootstrap.Collapse(menuToggle, {
            toggle: false,
          });
          bsCollapse.hide();
        }
        window.scrollTo(0, 0);
      }
    },
  },
  mounted() {},
};
</script>

<style>
</style>