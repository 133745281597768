<template>
  <div>
    <nav class>
      <div class="container pb-1">
        <div class="row my-4">
          <div class="col-4 my-auto text-center blog-logo-sml">
            <router-link to="/">
              <img
                src="@/assets/images/header/green.png"
                alt="Amchara Residential Health Retreats"
                class="mx-auto"
              />
              <h4 class="text-green mt-2 font-weight-bold">
                Health
                <span class="font-weight-normal">Retreats</span>
              </h4>
            </router-link>
          </div>

          <div class="col-4 my-auto text-center blog-logo-reg">
            <router-link to="/blog">
              <img
                src="@/assets/images/header/grey.png"
                alt="Amchara Residential Health Retreats"
                class="mx-auto"
              />
              <h2 class="text-grey mt-2 font-weight-bold">
                Health
                <span class="font-weight-normal">Hub</span>360°
              </h2>
            </router-link>
          </div>

          <div class="col-4 my-auto text-center blog-logo-sml">
            <a href="https://www.amchara360.com">
              <img
                src="@/assets/images/header/blue.png"
                alt="Amchara Personalised 360 Health"
                class="mx-auto"
              />
              <h4 class="text-green mt-2 font-weight-bold am-text-blue">
                360°
                <span class="font-weight-normal">Health</span>
              </h4>
            </a>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>